import React, {useEffect, useState} from "react";
import Header from "./Header";
import Buttons from "./Buttons";
import Question from "./Question";
import {useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import ApplyForm from "./ApplyForm";
import {Box, Button, CircularProgress, Container, Stack, TextField, Typography} from "@mui/material";
import LogoBox from "./LogoBox";
import Agenda from "./Agenda";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {DisplayName} from "./DisplayName";
import {useLocalStorage} from "../functions/customHook/useLocalStorage";
import {PinnedQuestions} from "./PinnedQuestions";

export default function VoteContent({user, event, loading, displayName, setDisplayName}) {
    const {id} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const [eventPlaying, setEventPlaying] = useState('')
    const [pinned, pinnedLoading, pinnedError] = useCollection(firestore.collection('users/plesh/events/' + id + '/queue').where("pinned", "==", true), {})
    const url = 'users/plesh/events/'
    const [theme, themeLoading, themeError] = useDocument(firestore.collection('users/plesh/events/' + id + '/themes').doc(event?.data().theme))

    useEffect(() => {
        getIdPlaying()
    }, [])

    useEffect(() => {

    }, [displayName]);

    function getIdPlaying() {
        firestore.collection(url).doc(id).onSnapshot(s => {
            let e = s.data().elementPlaying
            let g = s.data().playing
            setIdPlaying(e)
            setEventPlaying(g)
        })
    }

    return (
        <Box>
            <Box sx={{background: 'white'}}>
                <Box sx={{height: '3rem'}}></Box>
                {event.data().logo && <LogoBox
                    logo={event.data().logoUrl ? event.data().logoUrl : 'https://storage.googleapis.com/2ndstage-public/logos/logo-2nd.png'}/>}
                <Box sx={{height: '1rem'}}></Box>
            </Box>
            <Box
                sx={{background: 'radial-gradient(80% 120px at 48% 120px, #232f5c 90%, white 91%) no-repeat', backgroundPosition:'bottom', height:'3rem', width:'100%', mt:'-0.2rem'}}
            />
            <Container maxWidth={'sm'}>
                <Stack direction={"column"} justifyContent={"start"} alignContent={"center"}
                       sx={{height: 'calc(73.6vh - 7rem)', py: 2, background: '#232f5c'}}
                       spacing={4}>
                    {
                        event.data().autenticazione &&
                        <DisplayName displayName={displayName} setDisplayName={setDisplayName}/>
                    }
                    {!pinnedLoading && !pinnedError && pinned ?
                        event.data().autenticazione
                            ?
                            (displayName !== '' && displayName) &&
                            <PinnedQuestions questions={pinned}/>
                            :
                            <PinnedQuestions questions={pinned}/>
                        :
                        <></>
                    }
                </Stack>
                {loading ?
                    <Box sx={{textAlign: 'center'}}>
                        <CircularProgress/>
                    </Box>
                    :
                    event.data().applausometro ?
                        event.data().autenticazione
                            ?
                            (displayName !== '' && displayName) &&
                            <Buttons url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                            :
                            <Buttons url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                        :
                        <></>
                }
            </Container>
            {(theme && !themeLoading && !themeError && !loading) ?
                event.data().autenticazione
                    ?
                    (displayName !== '' && displayName) &&
                    <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                    :
                    <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                :
                <></>
            }
        </Box>

    )
}

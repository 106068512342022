import React from "react";
import {Container} from "@mui/material";
import {QuestionSwitch} from "./Questions/QuestionSwitch";

export default function QuestionContent({elementPlaying, theme}) {
    return(
        <Container sx={{height:'75%', position:'relative', paddingTop:'20px', textAlign:'center'}}>
            <QuestionSwitch elementPlaying={elementPlaying} theme={theme}/>
        </Container>
    )
}
